import { useEffect, useState } from "react";
import PopupModal from "../../General/PopupModal/PopupModal";
import "./UserModal.scss";
import adminService from "../../../Services/admin.service";
import products from "../../../data/products";
import { useModal } from "../../../Contexts/ModalContext";
import LinkModal from "../LinkModal/LinkModal";
import AddProductToUserModal from "../AddProductToUserModal/AddProductToUserModal";
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';
import UpdateUserProductModal from "../UpdateUserProductModal/UpdateUserProductModal";
import { useSelector } from "react-redux";

function UserModal(props) {
    const { roles } = useSelector(state => state.auth);

    const { closeModal, openModal } = useModal();

    const [data, setData] = useState();
    const [resiData, setResiData] = useState();

    function hashCode(string) {
        var hash = 0;
        for (var i = 0; i < string.length; i++) {
            var code = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + code;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    const getAvatar = () => {
        if (!props.id)
            return "avatar1";

        let hash = hashCode(props.id);
        hash = Math.abs(hash);
        return "avatar" + ((hash % 3) + 1);
    }

    const refreshData = () => {
        adminService.getUser(props.id).then((data) => {
            setData(data);

            var resiOrder = data.activeOrders.find(x => x.productId == "86da73c4-47cc-43cd-a291-b61ec25d33d9");

            if (resiOrder)
                adminService.getResiData(resiOrder.properties["id"]).then((data) => {
                    setResiData(data);
                });
        });
    }

    useEffect(() => {
        refreshData();
    }, []);

    const getProductName = (id) => {
        var product = products.proxies.find(x => x.id == id);

        product ??= products.proxies[0].productOptions.find(x => x.id == id);

        product ??= products.servers.find(x => x.id == id);

        return product.name;
    }

    const getDetails = (order) => {
        let id = order.productId;        

        switch (id) {
            case "fc1c0b13-fa61-4b9e-b7f5-e86bd8c294b3":
            case "49932e88-35e0-4f02-aded-5e378b85765d":
            case "f0f3714d-bec9-4266-a983-596c0d4980e0":
            case "c342deac-14f5-42ab-a1a5-67c0ba713e9a":
            case "3bfcf146-f4ef-44a6-9bbb-6040a8f78cab":
                return "Quantity: " + order.properties["quantity"];
        }

        return "Quantity: 1";
    }

    const handleLink = () => {
        closeModal();
        openModal(LinkModal, data);
    }

    return (
        <PopupModal>
            <div className="user-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Overview ({props.id})</h3>
                </div>
                <div className="modal-body">
                    {data &&
                        <>
                            <div className="user-header-container">
                                <div className="avatar-container">
                                    {props.id && <img src={require(`../../../assets/avatars/${getAvatar()}.png`)} />}
                                </div>
                                <div className="name-container">
                                    <span className="bold">{data.lastName}</span>
                                    <span>{data.firstName}</span>
                                </div>
                                <div className="button-container">
                                    <button onClick={handleLink} className="default-button">Create Link</button>
                                    {roles?.includes("ADMIN") &&
                                        <button onClick={() => {openModal(AddProductToUserModal, data)}} className="default-button">Add product</button>
                                    }
                                </div>
                            </div>
                            <div className="user-data-container">
                                <div className="data-row">
                                    <label>Email</label>
                                    <span>{data.email}</span>
                                </div>
                                <div className="data-row">
                                    <label>Email Confirmed</label>
                                    <span>{data.emailConfirmed ? "Yes" : "No"}</span>
                                </div>
                                <div className="data-row">
                                    <label>Created On</label>
                                    <span>{data.createdOn}</span>
                                </div>
                                <div className="data-row">
                                    <label>Stripe ID</label>
                                    <span>{data.stripeId}</span>
                                </div>
                            </div>
                            {
                                data.activeOrders?.length > 0 &&
                                <>
                                    <span className="title">Active Orders</span>
                                    <div className="order-container">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Product</th>
                                                    <th>Expiry</th>
                                                    <th>Details</th>
                                                </tr>
                                                {
                                                    data.activeOrders.map((order, i) => (
                                                        <tr key={`order-${i}`}>
                                                            <td>
                                                                <div className="tooltip-container">
                                                                    <span className="tooltip-toggle">{order.id}</span>
                                                                    <div className="tooltip-content-container">
                                                                        <div className="tooltip-content">
                                                                            <ul className="tooltip-properties">
                                                                                {
                                                                                    Object.keys(order.properties).map((key, i) => (
                                                                                        <li key={`property-${i}`}>{`${key} -> ${order.properties[key]}`}</li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{getProductName(order.productId)}</td>
                                                            <td>{order.expiry}</td>
                                                            <td>
                                                                {
                                                                    order.productId != "86da73c4-47cc-43cd-a291-b61ec25d33d9" ? 
                                                                        getDetails(order) 
                                                                    : 
                                                                        (resiData ? 
                                                                            (roles?.includes("ADMIN") ?
                                                                                <span className="resi-data" onClick={() => openModal(UpdateUserProductModal, {...data, productId: order.productId, successCallback: refreshData})}>{`${resiData.trafficRemaining} / ${resiData.trafficLimit} GB`} <PencilIcon /></span> 
                                                                            :
                                                                                <span>{`${resiData.trafficRemaining} / ${resiData.trafficLimit} GB`}</span>
                                                                            )
                                                                            :
                                                                             ""
                                                                        )
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </PopupModal>
    )
}

export default UserModal;