import "./DashboardHome.scss";

import products from '../../../data/products';
import ProxyCard from "../../../Components/Landing/ProxyCard/ProxyCard";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import ServerCard from "../../../Components/Landing/ServerCard/ServerCard";
import IspCard from "../../../Components/Dashboard/IspCard/IspCard";
import ISPCard from "../../../Components/Landing/ISPCard/ISPCard";
import ResiCard from "../../../Components/Dashboard/ResiCard/ResiCard";
import MobileCard from "../../../Components/Dashboard/MobileCard/MobileCard";
import ServersCard from "../../../Components/Dashboard/ServersCard/ServersCard";
import { useSelector } from "react-redux";
import { useModal } from "../../../Contexts/ModalContext";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../../../Components/Dashboard/SuccessModal/SuccessModal";

function DashboardHome() {
    const location = useLocation();
    const navigate = useNavigate();
    const { openModal } = useModal();

    const [productType, setProductType] = useState("proxies");

    let width = window.innerWidth;

    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        let query = new URLSearchParams(location.search);

        let sessionId = query.get('session_id');
        let coinbase = query.get('coinbase');

        if (sessionId){
            openModal(SuccessModal, { id: sessionId, coinbase: coinbase });
            navigate('/dashboard');
        }

    }, [location])

    return (
        <div className="dashboard-page">
            <div className="dashboard-page-container">
                <h1>Welcome back, {user?.firstName}</h1>
                <div className="subtitle-container">
                    <span className="dashboard-subtitle">Active Plans</span>
                </div>

                <div className="plans-container">
                    {
                        width > 992 ?
                            <>
                                <IspCard />
                                <ResiCard />
                                <ServersCard />
                            </>
                            :
                            <Carousel centerMode showArrows={false} showStatus={false} dynamicHeight={false} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={15}>
                                <div className="carousel-item">
                                    <IspCard />
                                </div>
                                <div className="carousel-item">
                                    <ResiCard />
                                </div>
                                <div className="carousel-item">
                                    <ServersCard />
                                </div>
                            </Carousel>
                    }
                </div>

                <div className="subtitle-container">
                    <span className="dashboard-subtitle">Purchase Products</span>
                    <div className="product-selector">
                        <button className={productType == "proxies" ? "selected" : ""} onClick={() => setProductType("proxies")}>Proxies</button>
                        <button className={productType == "servers" ? "selected" : ""} onClick={() => setProductType("servers")}>Servers</button>
                    </div>
                </div>
                <div className="products-container">
                    {
                        productType == "proxies" ? (
                            width > 992 ?
                                products.proxies.map(element => {
                                    if(element.id == "fc1c0b13-fa61-4b9e-b7f5-e86bd8c294b3") {
                                        return (
                                            <ISPCard key={element.id} value={element} />
                                        )
                                    }
                                    return <ProxyCard key={element.id} value={element} />
                                })
                                :
                                <Carousel showArrows={false} showStatus={false} dynamicHeight={false} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={15}>
                                    {
                                        products.proxies.map(element =>
                                            <div key={element.id} className="carousel-item">
                                                <ProxyCard value={element} />
                                            </div>
                                        )
                                    }
                                </Carousel>)
                            :
                            <ServerCard value={products.servers} />
                    }
                </div>
            </div>
        </div>
    )
}

export default DashboardHome;