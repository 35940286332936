import { NavLink, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./DashboardLayout.scss"

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/hamburger.svg';

import Logo from "../../assets/images/logo.png";
import logoReversed from "../../assets/images/logo-reversed.png";
import { useEffect, useState } from "react";
import ModalRoot from "../../Components/General/PopupModal/ModalRoot";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Actions/auth";
import { getPrices } from "../../Actions/public";
import { getPlans } from "../../Actions/user";
import { useModal } from "../../Contexts/ModalContext";
import FeedbackModal from "../../Components/Dashboard/FeedbackModal/FeedbackModal";
import purchaseService from "../../Services/purchase.service";
import Loading from "../../Components/General/Loading/Loading";
import { PROXY_RESI } from "../../data/product";
import utils from "../../Utils/utils";

function DashboardLayout() {
    const mapping = {
        "86da73c4-47cc-43cd-a291-b61ec25d33d9": {
            "name": "Residential",
            "path": "/dashboard/residential",
            "icon": "resi-filled.svg"
        },
        "fc1c0b13-fa61-4b9e-b7f5-e86bd8c294b3": {
            "name": "UK ISP",
            "path": "/dashboard/isp",
            "icon": "isp-filled.svg"
        },
        "07fe74fc-3b2e-44fe-8e87-8046170317b2": {
            "name": "UK Subnet",
            "path": "/dashboard/isp",
            "icon": "isp-filled.svg"
        },
        "f0f3714d-bec9-4266-a983-596c0d4980e0": {
            "name": "US ISP",
            "path": "/dashboard/isp",
            "icon": "isp-filled.svg"
        },
        "465bf73b-9eaf-4950-8c01-07ff956957de": {
            "name": "US Subnet",
            "path": "/dashboard/isp",
            "icon": "isp-filled.svg"
        },
        "c342deac-14f5-42ab-a1a5-67c0ba713e9a": {
            "name": "EU ISP",
            "path": "/dashboard/isp",
            "icon": "isp-filled.svg"
        },
        "6eed22db-3c70-44a7-acfd-ffa75f258020": {
            "name": "EU Subnet",
            "path": "/dashboard/isp",
            "icon": "isp-filled.svg"
        },
        "9f9fb69d-2f68-4938-a66e-f73a9ef10cc5": {
            "name": "Basic Server",
            "path": "/dashboard/servers",
            "icon": "servers-filled.svg"
        },
        "10b3ffec-d9ce-4e53-b757-b4f860d353ed": {
            "name": "Advanced Server",
            "path": "/dashboard/servers",
            "icon": "servers-filled.svg"
        },
    }

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { openModal, closeModal } = useModal();

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const { isLoggedIn, roles, user: currentUser } = useSelector(state => state.auth);

    const { plans, resiData } = useSelector(state => state.user);

    const handleLogout = () => {
        dispatch(logout());

        navigate("/");
    };

    const handleFeedback = () => {
        openModal(FeedbackModal);
    };

    const handleLivechat = () => {
        if (window.zE) {
            window.zE('messenger', 'show');
            window.zE('messenger', 'open');
        }
    };

    const handleBilling = () => {
        purchaseService.getSession().then((data) => {
            window.location.href = data.url;
        });
    }

    const location = useLocation();
    let pageTitle = location.pathname.split('/').reverse()[0];

    switch (pageTitle) {
        case "isp":
            pageTitle = "ISP";
            break;
    }

    useEffect(() => {
        try {
            if (window.zE) {
                window.zE('messenger', 'hide');
                window.zE('messenger:on', 'close', () => window.zE('messenger', 'hide'));
            }
        } catch { }

        if (isLoggedIn) {
            dispatch(getPrices());
            dispatch(getPlans());
        }
    }, []);

    function hashCode(string) {
        var hash = 0;
        for (var i = 0; i < string.length; i++) {
            var code = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + code;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    const getAvatar = () => {
        if (!currentUser?.userId)
            return "avatar1";

        let hash = hashCode(currentUser?.userId);
        hash = Math.abs(hash);
        return "avatar" + ((hash % 3) + 1);
    }

    const getPlanChip = (productId, orders) => {
        if (productId == PROXY_RESI) {
            return resiData ? `${resiData.trafficRemaining.toFixed(2)} / ${resiData.trafficLimit.toFixed(2)}GB LEFT` : "Loading";
        }

        const amount = orders.filter(x => x.productId == productId).length;

        if (amount == 1) {
            var order = orders.find(x => x.productId == productId);
            return `${utils.getDaysLeft(order?.expiry)} DAYS LEFT`;
        } else {
            return "MULTIPLE PLANS"
        }
    };

    return !isLoggedIn ? <Navigate to="/" /> : (
        <div className="dashboard-container">
            <nav className="sidebar-container">
                <ModalRoot />
                <img className="logo" src={Logo} onClick={() => navigate("/")} />
                <div className="sidebar-body-container">
                    <span className="sub-title">TABS</span>
                    <NavLink end to='/dashboard' className="highlight">
                        <img src={require(`../../assets/icons/dashboard-filled.svg`).default} />
                        Dashboard
                    </NavLink>
                    <NavLink to='/dashboard/residential' className="highlight">
                        <img src={require(`../../assets/icons/resi-filled.svg`).default} />
                        Residential
                    </NavLink>
                    <NavLink to='/dashboard/isp' className="highlight">
                        <img src={require(`../../assets/icons/isp-filled.svg`).default} />
                        ISP
                    </NavLink>
                    <NavLink to='/dashboard/servers' className="highlight">
                        <img src={require(`../../assets/icons/servers-filled.svg`).default} />
                        Servers
                    </NavLink>
                    {
                        plans?.length > 0 &&
                        <>
                            <span className="sub-title">ACTIVE PLANS</span>
                            {
                                Array.from(new Set(plans.map(x => x.productId))).map(entry =>
                                    <NavLink key={entry} to={mapping[entry].path}>
                                        <img src={require(`../../assets/icons/${mapping[entry].icon}`)} />
                                        {mapping[entry].name}
                                        <div className="expiry-container">
                                            {
                                                getPlanChip(entry, plans) ? <span>{getPlanChip(entry, plans)}</span> : <Loading width="70px" height="15px"></Loading>
                                            }
                                        </div>
                                    </NavLink>
                                )
                            }
                        </>
                    }
                    {
                        (roles?.includes("ADMIN") || roles?.includes("ISP-ALL") || roles?.includes("MODERATOR")) &&
                        <>
                            <span className="sub-title">ADMIN</span>
                        </>
                    }
                    {
                        roles?.includes("ADMIN") &&
                        <NavLink to='/dashboard/admin' className="highlight" end>
                            <img src={require(`../../assets/icons/servers-filled.svg`).default} />
                            Analytics
                        </NavLink>
                    }
                    {
                        (roles?.includes("ADMIN") || roles?.includes("MODERATOR")) &&
                        <NavLink to='/dashboard/admin/users' className="highlight" end>
                            <img src={require(`../../assets/icons/servers-filled.svg`).default} />
                            Users
                        </NavLink>
                    }
                    {roles?.includes("ADMIN") &&
                        <NavLink to='/dashboard/admin/blog' className="highlight">
                            <img src={require(`../../assets/icons/servers-filled.svg`).default} />
                            Blog
                        </NavLink>
                    }
                    {
                        (roles?.includes("ADMIN") || roles?.includes("ISP-ALL")) &&
                        <>
                            <NavLink to='/dashboard/admin/isp' className="highlight">
                                <img src={require(`../../assets/icons/servers-filled.svg`).default} />
                                ISP
                            </NavLink>
                        </>
                    }
                    {
                        (roles?.includes("ADMIN") || roles?.includes("MODERATOR")) && <>
                            <NavLink to='/dashboard/admin/servers' className="highlight" end>
                                <img src={require(`../../assets/icons/servers-filled.svg`).default} />
                                Servers
                            </NavLink>
                        </>
                    }
                </div>
                <div className="sidebar-footer-container">
                    <a href="https://primed-proxies.gitbook.io/primed-proxies-documentation/">
                        <img src={require(`../../assets/icons/documentation.svg`).default} />
                        Documentation
                    </a>
                    <a onClick={handleLivechat}>
                        <img src={require(`../../assets/icons/support.svg`).default} />
                        Live Support
                    </a>
                    <a onClick={handleFeedback}>
                        <img src={require(`../../assets/icons/feedback.svg`).default} />
                        Send Feedback
                    </a>
                    <div className="sidebar-profile-container">
                        <div className="avatar-container">
                            {currentUser && <img src={require(`../../assets/avatars/${getAvatar()}.png`)} />}
                        </div>
                        <div className="profile-details-container">
                            <span className="name">{currentUser?.firstName} {currentUser?.lastName}</span>
                            <span>{currentUser?.email}</span>
                        </div>
                        <div className="tooltip-container">
                            <img className="tooltip-toggle" src={require(`../../assets/icons/profile-selector.svg`).default} />
                            <div className="tooltip-content-container">
                                <div className="tooltip-content">
                                    <a onClick={handleBilling}>
                                        <img src={require(`../../assets/icons/billing.svg`).default} />
                                        Billing
                                    </a>
                                    <hr />
                                    <a onClick={handleLogout}>
                                        <img src={require(`../../assets/icons/leave.svg`).default} />
                                        Finish session
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={handleNavCollapse} className="toggle-icon">
                    <HamburgerIcon />
                </button>
            </nav>

            <div className="dashboard-body-container">
                <div className="dashboard-title-container">
                    <span>{pageTitle}</span>
                </div>
                <Outlet />
            </div>

            <div className="dashboard-mobile-overlay-nav">
                <NavLink to='/dashboard/residential'>
                    <img src={require(`../../assets/icons/resi-filled.svg`).default} />
                    Residential
                </NavLink>
                <NavLink to='/dashboard/isp'>
                    <img src={require(`../../assets/icons/isp-filled.svg`).default} />
                    ISP
                </NavLink>
                <NavLink end to='/dashboard'>
                    <img src={require(`../../assets/icons/dashboard-filled.svg`).default} />
                    Home
                </NavLink>
                <NavLink to='/dashboard/servers'>
                    <img src={require(`../../assets/icons/servers-filled.svg`).default} />
                    Servers
                </NavLink>
            </div>

            <div className={`${!isNavCollapsed ? 'open' : ''} dashboard-mobile-nav`}>
                <div className="mobile-header">
                    <img src={logoReversed} alt="Logo" />
                    <button onClick={handleNavCollapse}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="mobile-body">
                    <div className="avatar-container">
                        {currentUser && <img src={require(`../../assets/avatars/${getAvatar()}.png`)} />}
                    </div>
                    <div className="profile-details-container">
                        <span className="name">{currentUser?.firstName} {currentUser?.lastName}</span>
                        <span>{currentUser?.email}</span>
                    </div>
                    <ul>
                        <li>
                            <a onClick={handleBilling}>Billing</a>
                        </li>
                        <li>
                            <a onClick={handleLogout}>Finish Session</a>
                        </li>
                        <hr />
                        <li>
                            <a href="https://primed-proxies.gitbook.io/primed-proxies-documentation/">Documentation</a>
                        </li>
                        <li>
                            <a onClick={handleLivechat}>Live Support</a>
                        </li>
                        <li>
                            <a onClick={handleFeedback}>Send Feedback</a>
                        </li>
                    </ul>
                </div>
                <div className="mobile-footer">
                    <a>Privacy Policy</a>
                    <a>Terms of Use</a>
                    <p>© {new Date().getFullYear()} Primed Project R Ltd</p>
                    <p>Registered at 42-44 Bishopsgate, London, United Kingdom, EC2N 4AH · Company no. 13043119</p>
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout;