import "./ISPCard.scss";

import Slider from "../Slider/Slider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../General/Loading/Loading";
import { useModal } from "../../../Contexts/ModalContext";
import LoginModal from "../../Auth/LoginModal/LoginModal";
import purchaseService from "../../../Services/purchase.service";
import publicService from "../../../Services/public.service";
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';

function ISPCard(props) {
    const { freeProxiesClaimed } = useSelector(state => state.auth);

    const [currentAmount, setCurrentAmount] = useState();
    const [currentTab, setCurrentTab] = useState("Divisions");

    const { prices } = useSelector(state => state.publics);
    const { isLoggedIn } = useSelector(state => state.auth);

    const { openModal, closeModal } = useModal();

    const [isLoading, setLoading] = useState(false);
    const [isLoadingCoinbase, setLoadingCoinbase] = useState(false);
    const [isFetchingStock, setFetchingStock] = useState(false);

    const [showRegionDropdown, setShowRegionDropdown] = useState(false);

    const [selectedRegion, setSelectedRegion] = useState();

    const [stock, setStock] = useState(-1);


    useEffect(() => {
        if (selectedRegion && props.changeSubProduct) {
            props.changeSubProduct(
                currentTab == "Subnet" ?
                    props.value.productOptions.find(x => x.type == "Subnet" && x.region == selectedRegion.region) :
                    selectedRegion
            );
        }
    }, [selectedRegion, currentTab]);

    function handleAmountChange(amount) {
        setCurrentAmount(amount);

        if (amount === product().maximum && props.onReachedMax)
            props.onReachedMax(props.value.name);
    }

    function onToggle() {
        props.onToggle(props.value);
    }

    const handlePurchase = (coinbase) => {
        coinbase ? setLoadingCoinbase(true) : setLoading(true);

        const request = { productId: product().id, quantity: currentAmount, coinbase: coinbase };

        if (!isLoggedIn) {
            openModal(LoginModal, request);
            coinbase ? setLoadingCoinbase(false) : setLoading(false);
        } else {
            (coinbase ? purchaseService.purchaseCoinbase(request) : purchaseService.purchase(request)).then((data) => {
                window.location.href = data.url;
            });
        }
    }

    const product = () => prices?.find(x => x.id == (currentTab == "Subnet" ? props.value.productOptions.find(x => x.type == "Subnet" && x.region == selectedRegion.region).id : selectedRegion.id));

    useEffect(() => {
        if (!currentAmount && selectedRegion && product()) {
            setCurrentAmount(product().minimum);
        }
    }, [prices])

    useEffect(() => {
        setSelectedRegion(props.value.productOptions.filter(x => x.type == "ISP")[0]);

    }, [])

    useEffect(() => {
        setStock(-1);
        if (selectedRegion) {
            setFetchingStock(true);
            const id = currentTab == "Subnet" ? props.value.productOptions.find(x => x.type == "Subnet" && x.region == selectedRegion.region).id : selectedRegion.id;
            publicService.getStock(id).then((data) => {
                setStock(data.availability);
                setFetchingStock(false);
            }).catch(() => {
                setStock(0);
                setFetchingStock(false);
            });
        }
    }, [selectedRegion, currentTab]);

    useEffect(() => {
        if (freeProxiesClaimed) {
            closeModal();
        }
    }, [freeProxiesClaimed]);

    const keyPoints = () => {
        if (!selectedRegion) return [];

        let features = selectedRegion.features;
        let flag = selectedRegion.flag;

        if (currentTab == "Subnet") {
            features = props.value.productOptions.find(x => x.type == "Subnet" && x.region == selectedRegion.region).features;
            flag = props.value.productOptions.find(x => x.type == "Subnet" && x.region == selectedRegion.region).flag;
        }

        return features.map((point, index) => (
            <div key={"feature-" + index} className={`details-row ${index < 2 && "details-row-highlighted"}`}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.6">
                        <path d="M5.41602 10.4163L8.33268 13.333L14.5827 7.08301" stroke="currentColor" strokeWidth={index < 2 ? "2.5" : "1.5"} strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
                <span>{point}</span>
                {
                    index == 1 &&
                    <img className="flag-icon" src={require(`../../../assets/icons/flags/${flag}`)}/>
                }
            </div>
        ))
    }

    return (
        <div id={props.value.id} className={`isp-card proxy-card ${showRegionDropdown ? "showRegionDropdown" : ""}`}>
            {
                !!selectedRegion &&
                <>
                    <div className="blur-overlay"></div>
                    <p className="region-selector-label">
                        select region
                    </p>
                    <div className="proxy-card-header">
                        <div className="region-selector-wrapper">
                            <div className={`region-selector`} onClick={() => setShowRegionDropdown(!showRegionDropdown)} onBlur={() => setShowRegionDropdown(false)} onMouseEnter={() => setShowRegionDropdown(true)} onMouseLeave={() => setShowRegionDropdown(false)}>
                                <div className="icon-container">
                                    <img alt={props.value.name} src={require(`../../../assets/icons/${props.value.icon}`)} />
                                </div>
                                <span className="proxy-card-title">{selectedRegion.name}</span>
                                <div className="arrow-container">
                                    <ArrowDownIcon />
                                </div>
                                <div className={`region-selector-dropdown`}>
                                    {
                                        props.value.productOptions.filter(x => x.type == "ISP").map((option) => (
                                            <div key={option.id} className="region-selector-dropdown-item" onClick={(e) => { setSelectedRegion(option); setShowRegionDropdown(false); }}>
                                                <div className="region-selector-dropdown-item-flag">
                                                    <img alt={option.name} src={require(`../../../assets/icons/flags/${option.icon}`)} />
                                                </div>
                                                <span className="region-selector-dropdown-item-text">{option.name}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="product-selector">
                            <button className={currentTab === "Divisions" ? "selected" : ""} onClick={() => { setCurrentTab("Divisions"); setCurrentAmount(10) }}>Divisions</button>
                            <button className={currentTab === "Subnet" ? "selected" : ""} onClick={() => { setCurrentTab("Subnet"); setCurrentAmount(1) }}>Subnet</button>
                        </div>
                    </div>
                    <div className="proxy-card-body">
                        {
                            props.extended ?
                                <span className="proxy-card-description">{props.value.description}</span>
                                : <></>
                        }
                        <div className="price-container">
                            {
                                (!product()) ?
                                    <Loading height="35px" width="100px" />
                                    :
                                    <>
                                        <span className="price-total">£{(currentAmount ?? 1) * product().pricePerUnit}</span>
                                        <span className="price-detail">£{product().pricePerUnit.toFixed(2)} / {product().priceDescription}</span>
                                    </>
                            }
                        </div>
                        <div className="slider-container">
                            {
                                (!product()) ?
                                    <Loading height="35px" width="100%" />
                                    :
                                    <>
                                        <div className="slider-header">
                                            <span>Quantity</span>
                                            <span className="amount">{currentAmount} {currentAmount == 1 ? (currentTab == "Subnet" ? "Subnet" : props.value.unit) : props.value.units}</span>
                                        </div>
                                        <div className="slider-body">
                                            <Slider disabled={isLoading} min={product().minimum} max={product().maximum} step={product().steps} value={currentAmount} onChange={handleAmountChange} />
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="buy-button-container">
                            <button className={`${stock < 0 || stock < currentAmount ? "buy-now oos" : "buy-now"}`} onClick={() => handlePurchase(false)} disabled={isLoading || stock < currentAmount}>
                                {(isLoading || isFetchingStock) && <div className="loader"></div>}
                                {stock < 0 ? "Fetching availability" : (stock < currentAmount ? "Not enough in stock" : props.buyText ?? (props.showDetails ? "Purchase" : "Buy Now"))}
                            </button>
                            {
                                props.value.coinbase && !isFetchingStock &&
                                <button className={`${stock < 0 || stock < currentAmount ? "buy-now oos" : "buy-now"} coinbase`} onClick={() => handlePurchase(true)} disabled={isLoadingCoinbase || stock < currentAmount}>
                                    {(isLoadingCoinbase || isFetchingStock) && <div className="loader"></div>}
                                    <img src={require('../../../assets/icons/coinbase.svg').default} />
                                    {stock < 0 ? "Fetching availability" : (stock < currentAmount ? "Not enough in stock" : props.buyText ? `${props.buyText} with Coinbase` : (props.showDetails ? "Purchase" : "Pay with Coinbase"))}
                                </button>
                            }
                        </div>
                        {
                            props.extended && props.onToggle ?
                                <button className="show-details" onClick={onToggle}>{props.showDetails ? "Hide Product Details" : "Show Product Details"}</button>
                                : <></>
                        }
                    </div>
                    <div className="proxy-card-footer">
                        <span className="details-title">Features</span>
                        <div className="details-container">
                            {
                                keyPoints()
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ISPCard;