import GenerateResult from "../../../Components/Dashboard/GenerateResult/GenerateResult";
import OrderDetails from "../../../Components/Dashboard/OrderDetails/OrderDetails";
import OrderSelector from "../../../Components/Dashboard/OrderSelector/OrderSelector";
import "./Isp.scss"

import products from '../../../data/products';
import ProxyCard from "../../../Components/Landing/ProxyCard/ProxyCard";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PROXY_ISP } from "../../../data/product";
import userService from "../../../Services/user.service";
import { SET_ISPS } from "../../../Actions/type";
import MultiOrder from "../../../Components/Dashboard/MultiOrder/MultiOrder";
import ISPCard from "../../../Components/Landing/ISPCard/ISPCard";

function Isp() {
    let width = window.innerWidth;

    const dispatch = useDispatch();
    const { plans, isps } = useSelector(state => state.user);

    const getOrders = () => plans?.filter(x =>
        x.productId === "fc1c0b13-fa61-4b9e-b7f5-e86bd8c294b3" || // UK ISP
        x.productId === "07fe74fc-3b2e-44fe-8e87-8046170317b2" || // UK Subnet
        x.productId === "f0f3714d-bec9-4266-a983-596c0d4980e0" || // US ISP 
        x.productId === "465bf73b-9eaf-4950-8c01-07ff956957de" || // US Subnet
        x.productId === "c342deac-14f5-42ab-a1a5-67c0ba713e9a" || // EU ISP
        x.productId === "6eed22db-3c70-44a7-acfd-ffa75f258020"    // EU Subnet
    )

    return (
        <>
            {
                !getOrders() || getOrders()?.length <= 0 ?
                    <>
                        <div className="empty-page-container">
                            <div className="empty-container">
                                <img src={require(`../../../assets/icons/locked.svg`).default} />
                                <div className="notice">
                                    <span>You don't have any <b>ISP</b> plans</span>
                                    <span>You can purchase one <Link to="/dashboard">here</Link>.</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="dashboard-page isp-page-container">
                            <div className="left-container">
                                <div className="data-container">
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">Proxy List</span>
                                    </div>
                                    <OrderSelector title="ISP Orders" subtitle="Generate via Order ID below to access your plan" orders={getOrders()} onGenerate={(order) => userService.getIsps(order).then((data) => dispatch({
                                        type: SET_ISPS,
                                        payload: data,
                                    }))} />
                                    {
                                        width < 992 ? (
                                            <div className="result-container">
                                                <GenerateResult proxies={isps} />
                                            </div>
                                        ) : <></>
                                    }
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">Active Plans</span>
                                    </div>
                                    {getOrders() && getOrders().length > 1 ? <MultiOrder orders={getOrders()} /> : <OrderDetails order={getOrders()[0]} />}
                                </div>
                                {
                                    width >= 992 ? (
                                        <div className="result-container">
                                            <GenerateResult proxies={isps} />
                                        </div>
                                    ) : <></>
                                }
                            </div>
                            <div className="purchase-container">
                                <div className="subtitle-container">
                                    <span className="dashboard-subtitle">Purchase</span>
                                </div>
                                <ISPCard value={products.proxies[0]} />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Isp;