import "./DetailsCard.scss";

function DetailsCard(props) {
    return (
        <div className="details-card">
            <div className="details-card-header">
                <span className="details-card-header-title">{props.value.question}</span>
                <span className="details-card-header-subtitle">{props.value.answer}</span>
            </div>
            <div className="details-card-body">
                {
                    props.value.keypoints.map((point, i) => (
                        <div key={"keypoint-" + i} className="details-keypoint">
                            <div className="details-keypoint-title-container">
                                <img src={require(`../../../assets/icons/${point.icon}`)} />
                                <span className="details-keypoint-title">{point.title}</span>
                            </div>
                            <span className="details-keypoint-desc">{point.description}</span>
                        </div>
                    ))
                }
            </div>
            <div className="details-card-footer">
                {
                    props.value.footer.map((el, i) => (
                        <div key={"footer-item-" + i} className="details-card-footer-item">
                            <img src={require(`../../../assets/icons/${el.icon}`)} />
                            <span>{el.name}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default DetailsCard;