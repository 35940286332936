import Footer from "../../Components/Landing/Footer/Footer";
import ProxyCard from "../../Components/Landing/ProxyCard/ProxyCard";
import DetailsCard from "../../Components/Landing/DetailsCard/DetailsCard";
import "./ProductDetails.scss";

import products from '../../data/products';
import ServerCard from "../../Components/Landing/ServerCard/ServerCard";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ISPCard from "../../Components/Landing/ISPCard/ISPCard";
import { useState } from "react";

function ProductDetails({ product }) {

    const page = product.path == "servers" ? products.serverDetails : product;
    
    const [details, setDetails] = useState(product.details);

    const changeSubProduct = (subProduct) => {
        setDetails({...product.details, keypoints: subProduct.keypoints, footer: subProduct.footer});
    }

    return (
        <>
            <Helmet>
                <title>{page.title}</title>
                <meta name="description"
                    content={page.description}></meta>
                <meta property="og:type" content="website"></meta>
                <meta property="og:title" content={page.title}></meta>
                <meta property="og:description"
                    content={page.description}></meta>
                <meta property="twitter:title" content={page.title}></meta>
                <meta property="twitter:description"
                    content={page.description}></meta>
            </Helmet>
            <div className="product-page">
                <div className="product-details-container">
                    {
                        product && product.path == "servers"
                            ?
                            <>
                                <ServerCard value={products.servers} extended />
                                <DetailsCard value={products.serverDetails} />
                            </>
                            :
                            <>
                                {(product.id == "fc1c0b13-fa61-4b9e-b7f5-e86bd8c294b3" ?
                                    <ISPCard key={product.id} value={product} extended changeSubProduct={changeSubProduct}/>
                                    :
                                    <ProxyCard key={product.id} value={product} extended />
                                )}
                                <DetailsCard value={details} />
                            </>
                    }
                </div>
                <Link to="/#products" className="return-button">
                    <img className="arrow-icon" src={require(`../../assets/icons/arrow-left.svg`).default} />
                    Return Home
                </Link>
            </div>
            <Footer />
        </>
    )
}

export default ProductDetails;