import { Link } from "react-router-dom";
import Card from "../../General/Card/Card";

import "./IspCard.scss"
import { useSelector } from "react-redux";
import { PROXY_ISP, PROXY_MOBILE } from "../../../data/product";
import utils from "../../../Utils/utils";

function IspCard() {

    const { plans } = useSelector(state => state.user);

    const getOrders = () => plans?.filter(x => 
        x.productId === "fc1c0b13-fa61-4b9e-b7f5-e86bd8c294b3" || // UK ISP
        x.productId === "07fe74fc-3b2e-44fe-8e87-8046170317b2" || // UK Subnet
        x.productId === "f0f3714d-bec9-4266-a983-596c0d4980e0" || // US ISP 
        x.productId === "465bf73b-9eaf-4950-8c01-07ff956957de" || // US Subnet
        x.productId === "c342deac-14f5-42ab-a1a5-67c0ba713e9a" || // EU ISP
        x.productId === "6eed22db-3c70-44a7-acfd-ffa75f258020"    // EU Subnet
    )

    return (
        <Card classes="isp-card">
            <div className="card-header">
                <div className="card-icon">
                    <img src={require(`../../../assets/icons/isp-filled.svg`).default} />
                </div>
                <span className="card-title">ISP</span>
                {
                    getOrders()?.length > 0 && <div className="expiry-container">
                        <span>{getOrders() && getOrders().length > 1 ? "MULTIPLE PLANS" : `${utils.getDaysLeft(getOrders()[0].expiry)} DAYS LEFT`}</span>
                    </div>
                }
            </div>
            <div className="card-body">
                {
                    !getOrders() || getOrders().length <= 0 ?
                        <>
                            <img src={require(`../../../assets/icons/locked.svg`).default} />
                            <div className="notice">
                                <span>You don't have any <b>ISP</b> plans</span>
                                <span>You can purchase one below.</span>
                            </div>
                        </>
                        :
                        <>
                            <h1>{getOrders().reduce((partialSum, a) => partialSum + +a.properties["quantity"], 0)}</h1>
                            <div className="notice">
                                <span>Active proxies available.</span>
                                <span>Click <Link to="/dashboard/isp">here</Link> to view your proxy list.</span>
                            </div>
                        </>
                }
            </div>
        </Card>
    )
}

export default IspCard;